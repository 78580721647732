/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A serializer that allows user creation without setting a password. This is used for passwordless registration in the Cart checkout process.
 * @export
 * @interface CartCheckout
 */
export interface CartCheckout {
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CartCheckout
     */
    communicationConsent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    productId: string;
    /**
     * 
     * @type {any}
     * @memberof CartCheckout
     */
    filingDetails: any | null;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    reportType: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    companyUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CartCheckout
     */
    cartConfig: string;
}

/**
 * Check if a given object implements the CartCheckout interface.
 */
export function instanceOfCartCheckout(value: object): value is CartCheckout {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('filingDetails' in value) || value['filingDetails'] === undefined) return false;
    if (!('reportType' in value) || value['reportType'] === undefined) return false;
    if (!('cartConfig' in value) || value['cartConfig'] === undefined) return false;
    return true;
}

export function CartCheckoutFromJSON(json: any): CartCheckout {
    return CartCheckoutFromJSONTyped(json, false);
}

export function CartCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartCheckout {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'],
        'businessName': json['business_name'] == null ? undefined : json['business_name'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'communicationConsent': json['communication_consent'] == null ? undefined : json['communication_consent'],
        'productId': json['product_id'],
        'filingDetails': json['filing_details'],
        'reportType': json['report_type'],
        'companyUuid': json['company_uuid'] == null ? undefined : json['company_uuid'],
        'cartConfig': json['cart_config'],
    };
}

export function CartCheckoutToJSON(value?: CartCheckout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'business_name': value['businessName'],
        'phone_number': value['phoneNumber'],
        'communication_consent': value['communicationConsent'],
        'product_id': value['productId'],
        'filing_details': value['filingDetails'],
        'report_type': value['reportType'],
        'company_uuid': value['companyUuid'],
        'cart_config': value['cartConfig'],
    };
}

