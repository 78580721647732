/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingTypeEd2Enum } from './FilingTypeEd2Enum';
import {
    FilingTypeEd2EnumFromJSON,
    FilingTypeEd2EnumFromJSONTyped,
    FilingTypeEd2EnumToJSON,
} from './FilingTypeEd2Enum';
import type { MailStateEnum } from './MailStateEnum';
import {
    MailStateEnumFromJSON,
    MailStateEnumFromJSONTyped,
    MailStateEnumToJSON,
} from './MailStateEnum';
import type { FloridaCorporationStatus } from './FloridaCorporationStatus';
import {
    FloridaCorporationStatusFromJSON,
    FloridaCorporationStatusFromJSONTyped,
    FloridaCorporationStatusToJSON,
} from './FloridaCorporationStatus';
import type { FeinStatusEnum } from './FeinStatusEnum';
import {
    FeinStatusEnumFromJSON,
    FeinStatusEnumFromJSONTyped,
    FeinStatusEnumToJSON,
} from './FeinStatusEnum';
import type { OfficerTypeEnum } from './OfficerTypeEnum';
import {
    OfficerTypeEnumFromJSON,
    OfficerTypeEnumFromJSONTyped,
    OfficerTypeEnumToJSON,
} from './OfficerTypeEnum';
import type { Officer } from './Officer';
import {
    OfficerFromJSON,
    OfficerFromJSONTyped,
    OfficerToJSON,
} from './Officer';

/**
 * 
 * @export
 * @interface Corporation
 */
export interface Corporation {
    /**
     * 
     * @type {number}
     * @memberof Corporation
     */
    readonly id: number;
    /**
     * The corporate document number.
     * @type {string}
     * @memberof Corporation
     */
    documentNumber?: string | null;
    /**
     * The corporate name.
     * @type {string}
     * @memberof Corporation
     */
    corporationName?: string | null;
    /**
     * Please select whether your business is Active or Inactive.
     * 
     * * `A` - Active
     * * `I` - Inactive
     * @type {FloridaCorporationStatus}
     * @memberof Corporation
     */
    status?: FloridaCorporationStatus | null;
    /**
     * Is the FEI/EIN included with the filing?
     * 
     * * `I` - Included
     * * `A` - Applied For
     * * `NA` - Not Applicable
     * @type {FeinStatusEnum}
     * @memberof Corporation
     */
    feinStatus?: FeinStatusEnum | null;
    /**
     * Type of filing.
     * 
     * * `DOMP` - Florida For Profit Corporation
     * * `DOMNP` - Florida Non-Profit Corporation
     * * `FORP` - Foreign Profit Corporation
     * * `FORNP` - Foreign Non-Profit Corporation
     * * `DOMLP` - Florida Limited Partnership
     * * `FORLP` - Foreign Limited Partnership
     * * `FLAL` - Florida Limited Liability Company
     * * `FORL` - Foreign Limited Liability Company
     * * `NPREG` - Non-Profit, Registration
     * * `MISC` - Miscellaneous
     * * `NR` - Name Reservation
     * * `TRUST` - Declaration of Trust
     * * `AGENT` - Designation of Registered Agent
     * * `RF` - Rejection Filing
     * * `TM` - Trademark
     * @type {FilingTypeEd2Enum}
     * @memberof Corporation
     */
    filingType?: FilingTypeEd2Enum | null;
    /**
     * Enter the street address of the business.
     * @type {string}
     * @memberof Corporation
     */
    businessAddress1?: string | null;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof Corporation
     */
    businessAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    businessCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof Corporation
     */
    businessState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    businessZip?: string | null;
    /**
     * Enter the street address for mail correspondence.
     * @type {string}
     * @memberof Corporation
     */
    mailAddress1?: string | null;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof Corporation
     */
    mailAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    mailCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof Corporation
     */
    mailState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    mailZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    mailCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    fileDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    feiNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    moreThanSixOfficersFlag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    lastTransactionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentName?: string | null;
    /**
     * 
     * @type {OfficerTypeEnum}
     * @memberof Corporation
     */
    registeredAgentType?: OfficerTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentMiddleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentSignature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentAddress1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof Corporation
     */
    registeredAgentState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    registeredAgentZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    readonly cleanedContactName: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    readonly cleanedContactLastName: string | null;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof Corporation
     */
    emailAddress?: string | null;
    /**
     * This is a UUID generated from the users email.         It will be populated automatically on save.
     * @type {string}
     * @memberof Corporation
     */
    uuidFromEmail?: string | null;
    /**
     * 
     * @type {Array<Officer>}
     * @memberof Corporation
     */
    officers: Array<Officer>;
    /**
     * What did they file their 2024 Annual Report?
     * @type {string}
     * @memberof Corporation
     */
    annualReport2024?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    filingState?: string | null;
    /**
     * This is a UUID generated from the corporation name.         It will be populated automatically on save.
     * @type {string}
     * @memberof Corporation
     */
    companyUuid?: string | null;
}



/**
 * Check if a given object implements the Corporation interface.
 */
export function instanceOfCorporation(value: object): value is Corporation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('cleanedContactName' in value) || value['cleanedContactName'] === undefined) return false;
    if (!('cleanedContactLastName' in value) || value['cleanedContactLastName'] === undefined) return false;
    if (!('officers' in value) || value['officers'] === undefined) return false;
    return true;
}

export function CorporationFromJSON(json: any): Corporation {
    return CorporationFromJSONTyped(json, false);
}

export function CorporationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Corporation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'documentNumber': json['document_number'] == null ? undefined : json['document_number'],
        'corporationName': json['corporation_name'] == null ? undefined : json['corporation_name'],
        'status': json['status'] == null ? undefined : FloridaCorporationStatusFromJSON(json['status']),
        'feinStatus': json['fein_status'] == null ? undefined : FeinStatusEnumFromJSON(json['fein_status']),
        'filingType': json['filing_type'] == null ? undefined : FilingTypeEd2EnumFromJSON(json['filing_type']),
        'businessAddress1': json['business_address_1'] == null ? undefined : json['business_address_1'],
        'businessAddress2': json['business_address_2'] == null ? undefined : json['business_address_2'],
        'businessCity': json['business_city'] == null ? undefined : json['business_city'],
        'businessState': json['business_state'] == null ? undefined : MailStateEnumFromJSON(json['business_state']),
        'businessZip': json['business_zip'] == null ? undefined : json['business_zip'],
        'mailAddress1': json['mail_address_1'] == null ? undefined : json['mail_address_1'],
        'mailAddress2': json['mail_address_2'] == null ? undefined : json['mail_address_2'],
        'mailCity': json['mail_city'] == null ? undefined : json['mail_city'],
        'mailState': json['mail_state'] == null ? undefined : MailStateEnumFromJSON(json['mail_state']),
        'mailZip': json['mail_zip'] == null ? undefined : json['mail_zip'],
        'mailCountry': json['mail_country'] == null ? undefined : json['mail_country'],
        'fileDate': json['file_date'] == null ? undefined : json['file_date'],
        'feiNumber': json['fei_number'] == null ? undefined : json['fei_number'],
        'moreThanSixOfficersFlag': json['more_than_six_officers_flag'] == null ? undefined : json['more_than_six_officers_flag'],
        'lastTransactionDate': json['last_transaction_date'] == null ? undefined : json['last_transaction_date'],
        'registeredAgentName': json['registered_agent_name'] == null ? undefined : json['registered_agent_name'],
        'registeredAgentType': json['registered_agent_type'] == null ? undefined : OfficerTypeEnumFromJSON(json['registered_agent_type']),
        'registeredAgentFirstName': json['registered_agent_first_name'] == null ? undefined : json['registered_agent_first_name'],
        'registeredAgentMiddleName': json['registered_agent_middle_name'] == null ? undefined : json['registered_agent_middle_name'],
        'registeredAgentLastName': json['registered_agent_last_name'] == null ? undefined : json['registered_agent_last_name'],
        'registeredAgentSignature': json['registered_agent_signature'] == null ? undefined : json['registered_agent_signature'],
        'registeredAgentAddress1': json['registered_agent_address_1'] == null ? undefined : json['registered_agent_address_1'],
        'registeredAgentAddress2': json['registered_agent_address_2'] == null ? undefined : json['registered_agent_address_2'],
        'registeredAgentCity': json['registered_agent_city'] == null ? undefined : json['registered_agent_city'],
        'registeredAgentState': json['registered_agent_state'] == null ? undefined : MailStateEnumFromJSON(json['registered_agent_state']),
        'registeredAgentZip': json['registered_agent_zip'] == null ? undefined : json['registered_agent_zip'],
        'cleanedContactName': json['cleaned_contact_name'],
        'cleanedContactLastName': json['cleaned_contact_last_name'],
        'emailAddress': json['email_address'] == null ? undefined : json['email_address'],
        'uuidFromEmail': json['uuid_from_email'] == null ? undefined : json['uuid_from_email'],
        'officers': ((json['officers'] as Array<any>).map(OfficerFromJSON)),
        'annualReport2024': json['annual_report_2024'] == null ? undefined : json['annual_report_2024'],
        'filingState': json['filing_state'] == null ? undefined : json['filing_state'],
        'companyUuid': json['company_uuid'] == null ? undefined : json['company_uuid'],
    };
}

export function CorporationToJSON(value?: Omit<Corporation, 'id'|'cleaned_contact_name'|'cleaned_contact_last_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'document_number': value['documentNumber'],
        'corporation_name': value['corporationName'],
        'status': FloridaCorporationStatusToJSON(value['status']),
        'fein_status': FeinStatusEnumToJSON(value['feinStatus']),
        'filing_type': FilingTypeEd2EnumToJSON(value['filingType']),
        'business_address_1': value['businessAddress1'],
        'business_address_2': value['businessAddress2'],
        'business_city': value['businessCity'],
        'business_state': MailStateEnumToJSON(value['businessState']),
        'business_zip': value['businessZip'],
        'mail_address_1': value['mailAddress1'],
        'mail_address_2': value['mailAddress2'],
        'mail_city': value['mailCity'],
        'mail_state': MailStateEnumToJSON(value['mailState']),
        'mail_zip': value['mailZip'],
        'mail_country': value['mailCountry'],
        'file_date': value['fileDate'],
        'fei_number': value['feiNumber'],
        'more_than_six_officers_flag': value['moreThanSixOfficersFlag'],
        'last_transaction_date': value['lastTransactionDate'],
        'registered_agent_name': value['registeredAgentName'],
        'registered_agent_type': OfficerTypeEnumToJSON(value['registeredAgentType']),
        'registered_agent_first_name': value['registeredAgentFirstName'],
        'registered_agent_middle_name': value['registeredAgentMiddleName'],
        'registered_agent_last_name': value['registeredAgentLastName'],
        'registered_agent_signature': value['registeredAgentSignature'],
        'registered_agent_address_1': value['registeredAgentAddress1'],
        'registered_agent_address_2': value['registeredAgentAddress2'],
        'registered_agent_city': value['registeredAgentCity'],
        'registered_agent_state': MailStateEnumToJSON(value['registeredAgentState']),
        'registered_agent_zip': value['registeredAgentZip'],
        'email_address': value['emailAddress'],
        'uuid_from_email': value['uuidFromEmail'],
        'officers': ((value['officers'] as Array<any>).map(OfficerToJSON)),
        'annual_report_2024': value['annualReport2024'],
        'filing_state': value['filingState'],
        'company_uuid': value['companyUuid'],
    };
}

