/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { ReminderStatusEnum } from './ReminderStatusEnum';
import {
    ReminderStatusEnumFromJSON,
    ReminderStatusEnumFromJSONTyped,
    ReminderStatusEnumToJSON,
} from './ReminderStatusEnum';
import type { FilingTypeEd2Enum } from './FilingTypeEd2Enum';
import {
    FilingTypeEd2EnumFromJSON,
    FilingTypeEd2EnumFromJSONTyped,
    FilingTypeEd2EnumToJSON,
} from './FilingTypeEd2Enum';
import type { MailStateEnum } from './MailStateEnum';
import {
    MailStateEnumFromJSON,
    MailStateEnumFromJSONTyped,
    MailStateEnumToJSON,
} from './MailStateEnum';
import type { FloridaCorporationStatus } from './FloridaCorporationStatus';
import {
    FloridaCorporationStatusFromJSON,
    FloridaCorporationStatusFromJSONTyped,
    FloridaCorporationStatusToJSON,
} from './FloridaCorporationStatus';
import type { FeinStatusEnum } from './FeinStatusEnum';
import {
    FeinStatusEnumFromJSON,
    FeinStatusEnumFromJSONTyped,
    FeinStatusEnumToJSON,
} from './FeinStatusEnum';
import type { OfficerTypeEnum } from './OfficerTypeEnum';
import {
    OfficerTypeEnumFromJSON,
    OfficerTypeEnumFromJSONTyped,
    OfficerTypeEnumToJSON,
} from './OfficerTypeEnum';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';
import type { Officer } from './Officer';
import {
    OfficerFromJSON,
    OfficerFromJSONTyped,
    OfficerToJSON,
} from './Officer';

/**
 * 
 * @export
 * @interface PatchedAnnualReport
 */
export interface PatchedAnnualReport {
    /**
     * 
     * @type {number}
     * @memberof PatchedAnnualReport
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly filingStatusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly jurisdictionDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly paymentStatusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly reportNameDisplay?: string;
    /**
     * 
     * @type {Array<Officer>}
     * @memberof PatchedAnnualReport
     */
    officers?: Array<Officer>;
    /**
     * 
     * @type {number}
     * @memberof PatchedAnnualReport
     */
    signingOfficer?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof PatchedAnnualReport
     */
    reportName?: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof PatchedAnnualReport
     */
    jurisdiction?: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof PatchedAnnualReport
     */
    filingStatus?: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof PatchedAnnualReport
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * Reminder status
     * 
     * * `do_not_remind` - Do Not Remind
     * * `queued_for_reminder` - Queued for Reminder
     * * `sent_1d_reminder` - Sent 1 Day Reminder
     * * `sent_7d_reminder` - Sent 7 Day Reminder
     * * `sent_14d_reminder` - Sent 14 Day Reminder
     * * `sent_30d_reminder` - Sent 30 Day Reminder
     * * `next_reminder_phase` - Next Reminder Phase
     * * `new_filing` - New Filing Alert
     * * `boi_needed` - BOI Data Needed
     * * `annual_to_boi_remind` - Annual to BOI Reminder
     * * `boi_data_series` - BOI Data Series
     * * `error` - Error
     * @type {ReminderStatusEnum}
     * @memberof PatchedAnnualReport
     */
    reminderStatus?: ReminderStatusEnum;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedAnnualReport
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedAnnualReport
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    emailReminderStatus?: string;
    /**
     * The corporate document number.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    documentNumber?: string | null;
    /**
     * The corporate name.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    corporationName?: string | null;
    /**
     * Please select whether your business is Active or Inactive.
     * 
     * * `A` - Active
     * * `I` - Inactive
     * @type {FloridaCorporationStatus}
     * @memberof PatchedAnnualReport
     */
    status?: FloridaCorporationStatus | null;
    /**
     * Type of filing.
     * 
     * * `DOMP` - Florida For Profit Corporation
     * * `DOMNP` - Florida Non-Profit Corporation
     * * `FORP` - Foreign Profit Corporation
     * * `FORNP` - Foreign Non-Profit Corporation
     * * `DOMLP` - Florida Limited Partnership
     * * `FORLP` - Foreign Limited Partnership
     * * `FLAL` - Florida Limited Liability Company
     * * `FORL` - Foreign Limited Liability Company
     * * `NPREG` - Non-Profit, Registration
     * * `MISC` - Miscellaneous
     * * `NR` - Name Reservation
     * * `TRUST` - Declaration of Trust
     * * `AGENT` - Designation of Registered Agent
     * * `RF` - Rejection Filing
     * * `TM` - Trademark
     * @type {FilingTypeEd2Enum}
     * @memberof PatchedAnnualReport
     */
    filingType?: FilingTypeEd2Enum | null;
    /**
     * The full address of the business.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessAddress?: string | null;
    /**
     * Enter the street address of the business.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessAddress1?: string | null;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof PatchedAnnualReport
     */
    businessState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessZip?: string | null;
    /**
     * Enter the 2-letter country code (e.g., US for United States).
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    businessCountry?: string | null;
    /**
     * Enter the street address for mail correspondence.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    mailAddress1?: string | null;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    mailAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    mailCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof PatchedAnnualReport
     */
    mailState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    mailZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    mailCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    fileDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    feiNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    moreThanSixOfficersFlag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    lastTransactionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    filingState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentMiddleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentSignature?: string | null;
    /**
     * 
     * @type {OfficerTypeEnum}
     * @memberof PatchedAnnualReport
     */
    registeredAgentType?: OfficerTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentAddress1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentCity?: string | null;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof PatchedAnnualReport
     */
    registeredAgentState?: MailStateEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    registeredAgentZip?: string | null;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    emailAddress?: string | null;
    /**
     * This is a UUID generated from the users email.         It will be populated automatically on save.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    uuidFromEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly cleanedContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    readonly cleanedContactLastName?: string | null;
    /**
     * Is the FEI/EIN included with the filing?
     * 
     * * `I` - Included
     * * `A` - Applied For
     * * `NA` - Not Applicable
     * @type {FeinStatusEnum}
     * @memberof PatchedAnnualReport
     */
    feinStatus?: FeinStatusEnum | null;
    /**
     * What did they file their 2024 Annual Report?
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    annualReport2024?: string | null;
    /**
     * This is a UUID generated from the corporation name.         It will be populated automatically on save.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    companyUuid?: string | null;
    /**
     * A copy of the submission transcript
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    submissionTranscript?: string | null;
    /**
     * Please type your name.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    signingOfficerSignature?: string | null;
    /**
     * Please type your name.
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    signingOfficerTitle?: string;
    /**
     * I am authorized to sign this report.
     * @type {boolean}
     * @memberof PatchedAnnualReport
     */
    signingOfficerAuthorization?: boolean;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof PatchedAnnualReport
     */
    contactEmail?: string | null;
    /**
     * The year the filing was filed for.
     * @type {number}
     * @memberof PatchedAnnualReport
     */
    filingYear?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedAnnualReport
     */
    team?: number;
}



/**
 * Check if a given object implements the PatchedAnnualReport interface.
 */
export function instanceOfPatchedAnnualReport(value: object): value is PatchedAnnualReport {
    return true;
}

export function PatchedAnnualReportFromJSON(json: any): PatchedAnnualReport {
    return PatchedAnnualReportFromJSONTyped(json, false);
}

export function PatchedAnnualReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedAnnualReport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'filingStatusDisplay': json['filing_status_display'] == null ? undefined : json['filing_status_display'],
        'jurisdictionDisplay': json['jurisdiction_display'] == null ? undefined : json['jurisdiction_display'],
        'paymentStatusDisplay': json['payment_status_display'] == null ? undefined : json['payment_status_display'],
        'reportNameDisplay': json['report_name_display'] == null ? undefined : json['report_name_display'],
        'officers': json['officers'] == null ? undefined : ((json['officers'] as Array<any>).map(OfficerFromJSON)),
        'signingOfficer': json['signing_officer'] == null ? undefined : json['signing_officer'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'reportName': json['report_name'] == null ? undefined : ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': json['jurisdiction'] == null ? undefined : JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': json['filing_status'] == null ? undefined : FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'reminderStatus': json['reminder_status'] == null ? undefined : ReminderStatusEnumFromJSON(json['reminder_status']),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'emailReminderStatus': json['email_reminder_status'] == null ? undefined : json['email_reminder_status'],
        'documentNumber': json['document_number'] == null ? undefined : json['document_number'],
        'corporationName': json['corporation_name'] == null ? undefined : json['corporation_name'],
        'status': json['status'] == null ? undefined : FloridaCorporationStatusFromJSON(json['status']),
        'filingType': json['filing_type'] == null ? undefined : FilingTypeEd2EnumFromJSON(json['filing_type']),
        'businessAddress': json['business_address'] == null ? undefined : json['business_address'],
        'businessAddress1': json['business_address_1'] == null ? undefined : json['business_address_1'],
        'businessAddress2': json['business_address_2'] == null ? undefined : json['business_address_2'],
        'businessCity': json['business_city'] == null ? undefined : json['business_city'],
        'businessState': json['business_state'] == null ? undefined : MailStateEnumFromJSON(json['business_state']),
        'businessZip': json['business_zip'] == null ? undefined : json['business_zip'],
        'businessCountry': json['business_country'] == null ? undefined : json['business_country'],
        'mailAddress1': json['mail_address_1'] == null ? undefined : json['mail_address_1'],
        'mailAddress2': json['mail_address_2'] == null ? undefined : json['mail_address_2'],
        'mailCity': json['mail_city'] == null ? undefined : json['mail_city'],
        'mailState': json['mail_state'] == null ? undefined : MailStateEnumFromJSON(json['mail_state']),
        'mailZip': json['mail_zip'] == null ? undefined : json['mail_zip'],
        'mailCountry': json['mail_country'] == null ? undefined : json['mail_country'],
        'fileDate': json['file_date'] == null ? undefined : json['file_date'],
        'feiNumber': json['fei_number'] == null ? undefined : json['fei_number'],
        'moreThanSixOfficersFlag': json['more_than_six_officers_flag'] == null ? undefined : json['more_than_six_officers_flag'],
        'lastTransactionDate': json['last_transaction_date'] == null ? undefined : json['last_transaction_date'],
        'filingState': json['filing_state'] == null ? undefined : json['filing_state'],
        'registeredAgentName': json['registered_agent_name'] == null ? undefined : json['registered_agent_name'],
        'registeredAgentFirstName': json['registered_agent_first_name'] == null ? undefined : json['registered_agent_first_name'],
        'registeredAgentMiddleName': json['registered_agent_middle_name'] == null ? undefined : json['registered_agent_middle_name'],
        'registeredAgentLastName': json['registered_agent_last_name'] == null ? undefined : json['registered_agent_last_name'],
        'registeredAgentSignature': json['registered_agent_signature'] == null ? undefined : json['registered_agent_signature'],
        'registeredAgentType': json['registered_agent_type'] == null ? undefined : OfficerTypeEnumFromJSON(json['registered_agent_type']),
        'registeredAgentAddress1': json['registered_agent_address_1'] == null ? undefined : json['registered_agent_address_1'],
        'registeredAgentAddress2': json['registered_agent_address_2'] == null ? undefined : json['registered_agent_address_2'],
        'registeredAgentCity': json['registered_agent_city'] == null ? undefined : json['registered_agent_city'],
        'registeredAgentState': json['registered_agent_state'] == null ? undefined : MailStateEnumFromJSON(json['registered_agent_state']),
        'registeredAgentZip': json['registered_agent_zip'] == null ? undefined : json['registered_agent_zip'],
        'emailAddress': json['email_address'] == null ? undefined : json['email_address'],
        'uuidFromEmail': json['uuid_from_email'] == null ? undefined : json['uuid_from_email'],
        'cleanedContactName': json['cleaned_contact_name'] == null ? undefined : json['cleaned_contact_name'],
        'cleanedContactLastName': json['cleaned_contact_last_name'] == null ? undefined : json['cleaned_contact_last_name'],
        'feinStatus': json['fein_status'] == null ? undefined : FeinStatusEnumFromJSON(json['fein_status']),
        'annualReport2024': json['annual_report_2024'] == null ? undefined : json['annual_report_2024'],
        'companyUuid': json['company_uuid'] == null ? undefined : json['company_uuid'],
        'submissionTranscript': json['submission_transcript'] == null ? undefined : json['submission_transcript'],
        'signingOfficerSignature': json['signing_officer_signature'] == null ? undefined : json['signing_officer_signature'],
        'signingOfficerTitle': json['signing_officer_title'] == null ? undefined : json['signing_officer_title'],
        'signingOfficerAuthorization': json['signing_officer_authorization'] == null ? undefined : json['signing_officer_authorization'],
        'contactEmail': json['contact_email'] == null ? undefined : json['contact_email'],
        'filingYear': json['filing_year'] == null ? undefined : json['filing_year'],
        'team': json['team'] == null ? undefined : json['team'],
    };
}

export function PatchedAnnualReportToJSON(value?: Omit<PatchedAnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'officers': value['officers'] == null ? undefined : ((value['officers'] as Array<any>).map(OfficerToJSON)),
        'signing_officer': value['signingOfficer'],
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'reminder_status': ReminderStatusEnumToJSON(value['reminderStatus']),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'email_reminder_status': value['emailReminderStatus'],
        'document_number': value['documentNumber'],
        'corporation_name': value['corporationName'],
        'status': FloridaCorporationStatusToJSON(value['status']),
        'filing_type': FilingTypeEd2EnumToJSON(value['filingType']),
        'business_address': value['businessAddress'],
        'business_address_1': value['businessAddress1'],
        'business_address_2': value['businessAddress2'],
        'business_city': value['businessCity'],
        'business_state': MailStateEnumToJSON(value['businessState']),
        'business_zip': value['businessZip'],
        'business_country': value['businessCountry'],
        'mail_address_1': value['mailAddress1'],
        'mail_address_2': value['mailAddress2'],
        'mail_city': value['mailCity'],
        'mail_state': MailStateEnumToJSON(value['mailState']),
        'mail_zip': value['mailZip'],
        'mail_country': value['mailCountry'],
        'file_date': value['fileDate'],
        'fei_number': value['feiNumber'],
        'more_than_six_officers_flag': value['moreThanSixOfficersFlag'],
        'last_transaction_date': value['lastTransactionDate'],
        'filing_state': value['filingState'],
        'registered_agent_name': value['registeredAgentName'],
        'registered_agent_first_name': value['registeredAgentFirstName'],
        'registered_agent_middle_name': value['registeredAgentMiddleName'],
        'registered_agent_last_name': value['registeredAgentLastName'],
        'registered_agent_signature': value['registeredAgentSignature'],
        'registered_agent_type': OfficerTypeEnumToJSON(value['registeredAgentType']),
        'registered_agent_address_1': value['registeredAgentAddress1'],
        'registered_agent_address_2': value['registeredAgentAddress2'],
        'registered_agent_city': value['registeredAgentCity'],
        'registered_agent_state': MailStateEnumToJSON(value['registeredAgentState']),
        'registered_agent_zip': value['registeredAgentZip'],
        'email_address': value['emailAddress'],
        'uuid_from_email': value['uuidFromEmail'],
        'fein_status': FeinStatusEnumToJSON(value['feinStatus']),
        'annual_report_2024': value['annualReport2024'],
        'company_uuid': value['companyUuid'],
        'submission_transcript': value['submissionTranscript'],
        'signing_officer_signature': value['signingOfficerSignature'],
        'signing_officer_title': value['signingOfficerTitle'],
        'signing_officer_authorization': value['signingOfficerAuthorization'],
        'contact_email': value['contactEmail'],
        'filing_year': value['filingYear'],
        'team': value['team'],
    };
}

